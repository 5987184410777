import { h } from 'preact'
import cn from 'classnames'

import { useTranslate } from '../hooks/translate.js'

import s from './salj_din_bil_page.module.scss'
import sLayout from './layout.module.scss'
import SellYourCarForm from './sell_your_car_form.jsx'

const StartPage = () => {
  const translate = useTranslate()

  return (
    <section className={cn(sLayout.page, s.base)}>
      <h1>{translate('Sälj din bil')}</h1>
      <SellYourCarForm className={s.form} />
      <section className={s.process}>
        <h1>Så går det till</h1>
        <ol role='list'>
          <li>
            <h2>Kontakt</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua.
            </p>
          </li>
          <li>
            <h2>Värdering av din bil</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua.
            </p>

            <ul>
              <li>Lorem ipsum dolor sit amet</li>
              <li>Lorem ipsum dolor sit amet</li>
              <li>Lorem ipsum dolor sit amet</li>
            </ul>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua.
            </p>
          </li>
          <li>
            <h2>Processen</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua.
            </p>
          </li>
          <li>
            <h2>Avslut</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Lorem ipsum dolor sit amet.
            </p>
          </li>
        </ol>
      </section>
    </section>
  )
}

export default StartPage
