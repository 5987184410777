import { h } from 'preact'
import { useCallback } from 'preact/hooks'
import cn from 'classnames'
import { route } from 'preact-router'
import { omit, isEmpty } from 'lowline'

import qs from 'mini-qs'

import serializeForm from '../../shared/utils/serialize_form.js'

import CarIcon from '../images/icon-car.svg'
import FuelTypeIcon from '../images/icon-fuel-type.svg'
import GearboxIcon from '../images/icon-gearbox.svg'
import SearchIcon from '../images/icon-search.svg'
import SortIcon from '../images/icon-sort.svg'

import Button from './button.jsx'
import Checkbox from './new_form/checkbox.jsx'
import Input from './new_form/input.jsx'
import DoubleRange from './new_form/double_range.jsx'
import Select from './new_form/select.jsx'

import s from './car_search_form.module.scss'

const equipments = [
  { label: 'Svensksåld', value: 'svensksåld' },
  { label: 'Fyrhjulsdrift', value: '4WD' },
  { label: 'Dragkrok', value: 'dragkrok' },
]

const CarSearchForm = ({ className, location, search }) => {
  const onSubmit = useCallback((e) => {
    e.preventDefault()

    const data = serializeForm(e.target)

    const search = !isEmpty(data) ? '?' + qs.stringify(data) : ''

    route(`/bilar${search}`)
  }, [])

  if (!search) {
    return null
  }

  const query = (location.search && omit(qs.parse(location.search.slice(1)), ['offset'])) || {}

  if (query.equipment && !Array.isArray(query.equipment)) {
    query.equipment = [query.equipment]
  }

  return (
    <form className={cn(s.base, className)} onSubmit={onSubmit} values={query} initialValues={query}>
      <Input
        className={s.search}
        name='search'
        label='Fritext'
        placeholder='Sök på modell, utrustning mm'
        icon={`url(${SearchIcon})`}
        defaultValue={query.search}
      />
      <Select
        className={s.brand}
        name='brand'
        label='Märke'
        placeholder='Alla'
        icon={`url(${CarIcon})`}
        options={search.brand}
        defaultValue={query.brand}
      />
      <Select
        className={s.transmission}
        name='transmission'
        label='Växellåda'
        placeholder='Alla'
        icon={`url(${GearboxIcon})`}
        options={search.transmission}
        defaultValue={query.transmission}
      />
      <Select
        className={s.fuelType}
        name='fuelType'
        label='Drivmedel'
        placeholder='Alla'
        icon={`url(${FuelTypeIcon})`}
        options={search.fuelType}
        defaultValue={query.fuelType}
      />
      <Select
        className={s.bodyType}
        name='bodyType'
        label='Fordonstyp'
        placeholder='Alla'
        icon={`url(${CarIcon})`}
        options={search.bodyType}
        defaultValue={query.bodyType}
      />

      <DoubleRange
        className={cn(s.doubleRange, s.price)}
        format='price'
        nameLower='priceFrom'
        nameUpper='priceTo'
        label='Pris'
        min={search.price.min}
        max={search.price.max}
        step={50000}
        defaultLower={query.priceFrom}
        defaultUpper={query.priceTo}
      />

      <DoubleRange
        className={cn(s.doubleRange, s.modelDate)}
        nameLower='modelDateFrom'
        nameUpper='modelDateTo'
        label='Modellår'
        min={search.modelDate.min}
        max={search.modelDate.max}
        step={1}
        defaultLower={query.modelDateFrom}
        defaultUpper={query.modelDateTo}
      />

      <DoubleRange
        className={cn(s.doubleRange, s.mileage)}
        format='number'
        nameLower='mileageFrom'
        nameUpper='mileageTo'
        label='Miltal'
        min={search.mileage.min}
        max={search.mileage.max}
        step={1000}
        defaultLower={query.mileageFrom}
        defaultUpper={query.mileageTo}
      />

      <div className={s.equipment}>
        {equipments.map(({ label, value }) => (
          <Checkbox
            name='equipment'
            label={label}
            value={value}
            defaultChecked={query.equipment && query.equipment.includes(value)}
          />
        ))}
        <Checkbox name='leasable' label='Leasbar' defaultChecked={query.leasable} />
      </div>

      <Select
        className={s.sort}
        name='sort'
        noEmpty
        icon={`url(${SortIcon})`}
        label='Sortera efter'
        defaultValue={query.sort}
        options={[
          { label: 'Senast inlagd', value: '' },
          { label: 'Modell', value: 'modell' },
          { label: 'Pris, fallande', value: '-price' },
          { label: 'Pris, stigande', value: 'price' },
          { label: 'Modellår, fallande', value: '-modelDate' },
          { label: 'Modellår, stigande', value: 'modelDate' },
        ]}
      />

      <Button type='submit' fullWidth className={s.button}>
        Sök
      </Button>
    </form>
  )
}

export default CarSearchForm
