import { h } from 'preact'
import cn from 'classnames'

import s from './logo.module.scss'

export default ({ className, children }) => (
  <a className={cn(s.base, className)} href='/'>
    <span>{children}</span>
  </a>
)
