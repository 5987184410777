import { h } from 'preact'
import { useEffect, useRef } from 'preact/hooks'

import s from './modal.module.scss'

const Modal = ({ children, onClose, open }) => {
  const dialogRef = useRef(null)

  useEffect(() => {
    if (open) {
      dialogRef.current.showModal()
    } else {
      dialogRef.current.close()
    }
  })

  return (
    <dialog className={s.base} ref={dialogRef} onCancel={onClose}>
      <button className={s.button} onClick={onClose}>
        Close
      </button>
      {children}
    </dialog>
  )
}

export default Modal
