import { h } from 'preact'
import { useCallback, useEffect } from 'preact/hooks'
import cn from 'classnames'
import { useDispatch, useSelector } from '@bmp/store/preact'
import { useTranslate } from '../hooks/translate.js'
import { fetchAll, fetchReviews } from '../store/reco_actions.js'
import Button from './button.jsx'

import Slider from './slider.jsx'

import s from './reco.module.scss'
import Review from './reco_review.jsx'

const Reco = () => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const reco = useSelector('reco')

  const onSlide = useCallback(({ currentView, viewCount }) => {
    if (currentView >= viewCount - 1) {
      dispatch(fetchReviews())
    }
  }, [])

  useEffect(() => {
    if (!reco || !reco.reviews) {
      dispatch(fetchAll(8))
    }
  }, [])

  return (
    <section className={s.base}>
      <header className={s.header}>
        <h1>
          <a href='https://www.reco.se/carson-sverige'>
            <span>Reco.se</span>
          </a>
        </h1>
        <div className={s.badges}>
          <div className={s.years} />
          <div className={cn(s.year, s.y2023)} />
          <div className={cn(s.year, s.y2022)} />
          <div className={cn(s.year, s.y2021)} />
          <div className={cn(s.year, s.y2020)} />
          <div className={cn(s.year, s.y2019)} />
          <div className={cn(s.year, s.y2018)} />
          <div className={cn(s.year, s.y2017)} />
          <div className={cn(s.year, s.y2016)} />
          <div className={cn(s.year, s.y2015)} />
        </div>
        <div>
          <div className={s.rating}>
            <span>{reco?.rating || '?'}</span>/5
          </div>
          <div>{translate('Från %s recensioner', reco?.reviewCount || 0)}</div>
        </div>
      </header>

      <Slider className={s.slider} onSlide={onSlide}>
        {reco?.reviews?.map((review) => (
          <Review review={review} key={review.id} className={s.slide} />
        ))}
      </Slider>

      <p className={s.footer}>
        <a href='https://www.reco.se/carson-sverige'>
          Läs alla <strong>{reco?.reviewCount || '?'}</strong> recensioner på Reco.se...
        </a>
      </p>

      <div className={s.bottom}>
        <div className={s.rating}>
          <span>{reco?.rating || '?'}</span>/5
        </div>
        <div>{translate('Från %s recensioner', reco?.reviewCount || 0)}</div>
      </div>

      <Button href='https://www.reco.se/carson-sverige'>Se alla</Button>
    </section>
  )
}

export default Reco
