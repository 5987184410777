import { h } from 'preact'

const Components = import.meta.glob('./*_page.jsx', { eager: true })

/** @type {import('preact').FunctionComponent<{ path: string, component: string }} Page */
const Route = ({ path, component }) => {
  const Component = Components['./' + component].default

  return Component && <Component path={path} />
}

export default Route
