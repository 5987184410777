export const fetchCars = (search) => (_getState, _dispatch, rek) =>
  rek(`/api/cars${search || ''}`).then(({ cars, pagination }) => ({
    cars,
    pagination,
  }))

export const unloadCars = () => () => ({
  cars: null,
  pagination: null,
})
