import { h } from 'preact'
import { useCallback, useEffect } from 'preact/hooks'
import { useDispatch, useSelector } from '@bmp/store/preact'

import useIsomorphicEffect from '../util/use_isomorphic_effect.js'
import { changeLocation } from '../store/location_actions.js'
import { fetchOpeningHours } from '../store/opening_hours_actions.js'
import { fetchOrganization } from '../store/organization_actions.js'
import { updateTranslations } from '../store/translation_actions.js'
import { updateContents } from '../store/contents_actions.js'
import ErrorPage from './error_page.jsx'
import NotFoundPage from './not_found_page.jsx'
import Footer from './footer.jsx'
import Header from './header.jsx'
import Route from './route.jsx'
import Router from './router.jsx'
import Head from './head.jsx'
import s from './layout.module.scss'

const Layout = ({ url }) => {
  const dispatch = useDispatch()
  const { error, locale, locales, location, openingHours, routes, organization } = useSelector([
    'error',
    'locale',
    'locales',
    'location',
    'openingHours',
    'routes',
    'organization',
  ])

  useIsomorphicEffect(() => {
    if (!organization) dispatch(fetchOrganization())
    if (!openingHours) dispatch(fetchOpeningHours())
  }, [])

  useEffect(() => {
    dispatch(updateTranslations(location.route.id))
    dispatch(updateContents(location.route.id))
  }, [location.route && location.route.id])

  const handleRouterChange = useCallback((params) => {
    dispatch(changeLocation(params.url, params.current.props.route))
  }, [])

  return (
    <div id='app' className={s.base}>
      <Head>
        <title>{organization && organization.name}</title>
      </Head>

      <Header
        locales={locales}
        locale={locale}
        location={location}
        openingHours={openingHours}
        organization={organization || {}}
        routes={routes}
      />

      <main className={s.main}>
        {error && error.status !== 404 ? (
          <ErrorPage error={error} />
        ) : (
          <Router url={url} onChange={handleRouterChange}>
            {routes?.map((route) => (
              <Route
                key={route.path}
                route={route}
                locale={locale}
                location={location}
                path={
                  (
                    (route.locales.length > 0 && route.locales.find((routeLocale) => routeLocale.id === locale.id)) ||
                    route
                  ).path
                }
                component={route.component}
              />
            ))}
            <NotFoundPage default />
          </Router>
        )}
      </main>

      <Footer location={location} openingHours={openingHours} organization={organization} routes={routes} />
    </div>
  )
}

export default Layout
