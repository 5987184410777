import { h } from 'preact'
import { useCallback, useEffect, useRef, useState } from 'preact/hooks'
import { useDispatch, useSelector } from '@bmp/store/preact'
import cn from 'classnames'
import { CSSTransition } from 'preact-transitioning'

import { get, pick } from 'lowline'

import { fetchCars, unloadCars } from '../store/cars_actions.js'
import { fetchSearch } from '../store/search_actions.js'
import scrollIntoView from '../util/scroll_into_view.js'
import useIsomorphicEffect from '../util/use_isomorphic_effect.js'
import Head from './head.jsx'
import { useLoading } from '../contexts/loading.js'
import Pagination from './pagination.jsx'
import Car from './car.jsx'
import CarSearchForm from './car_search_form.jsx'

import s from './bilar_page.module.scss'

const endListener = (node, done) => node.addEventListener('transitionend', done, { once: true, capture: false })

const BilarPage = () => {
  const dispatch = useDispatch()
  const { cars, location, pagination, search } = useSelector(['cars', 'location', 'pagination', 'search'])
  const loading = useLoading()
  const [formVisible, setFormVisible] = useState(false)

  const topPagination = useRef()
  const searchFormContainer = useRef()

  const toggleForm = useCallback(() => {
    setFormVisible((formVisible) => {
      // if (!formVisible) {
      //   const el = searchFormContainer.current

      //   const height = el.scrollHeight

      //   el.style.height = `${height}px`
      // }

      return !formVisible
    })
  }, [])

  useIsomorphicEffect(() => {
    // TODO removing this conditional causes the SSR to get caught in loop
    if (import.meta.env.SSR && !cars) {
      const done = loading()

      dispatch(fetchCars(location.search)).then(done)
    }

    if (!search) {
      const done = loading()

      dispatch(fetchSearch()).then(done)
    }

    return () => dispatch(unloadCars())
  }, [])

  useEffect(() => {
    const done = loading()

    dispatch(fetchCars(location.search))
      .then(done)
      .then(() => {
        if (get(window.history, 'state.scrollTop') == null) {
          scrollIntoView(topPagination.current)
        }
      })
  }, [location.search])

  if (!cars) {
    return null
  }

  return (
    <section className={s.base}>
      <Head>
        <title> : Bilar</title>
      </Head>

      <h1>Bilar</h1>

      <div className={cn(s.search, formVisible && s.visible)}>
        <button className={cn(s.searchFormToggleButton, formVisible && s.visible)} onClick={toggleForm}>
          Filtrera
        </button>

        <CSSTransition
          alwaysMounted
          onEnter={(node) => (node.style.height = `${node.scrollHeight}px`)}
          onExit={(node) => (node.style.height = '0px')}
          in={formVisible}
          classNames={s}
          addEndListener={endListener}
        >
          <div className={cn(s.searchFormContainer)} ref={searchFormContainer}>
            <CarSearchForm location={location} search={search} />
          </div>
        </CSSTransition>
      </div>

      <Pagination
        className={cn(s.pagination, s.top)}
        {...pagination}
        {...pick(location, 'pathname', 'search')}
        forwardRef={topPagination}
      />

      <div className={cn(s.cars)}>
        {cars.length ? (
          cars.map((car) => <Car key={car.id} basePath={location.pathname} className={s.car} {...car} />)
        ) : (
          <div className={s.noMatchingCars}>Inga matchande bilar hittades</div>
        )}
      </div>

      <Pagination className={cn(s.pagination, s.bottom)} {...pagination} {...pick(location, 'pathname', 'search')} />
    </section>
  )
}

export default BilarPage
