import { h, Fragment } from 'preact'
import cn from 'classnames'

import s from './pagination.module.scss'

function getPages({ pathname, search, totalCount, limit, offset }) {
  const pages = []
  const searchParams = new URLSearchParams(search)
  const last = Math.floor(totalCount / limit)
  const current = offset / limit

  for (let i = 0; i <= last; i++) {
    if (i === 0) searchParams.delete('offset')
    else searchParams.set('offset', limit * i)

    const query = searchParams.toString()

    pages.push({
      path: pathname + (query ? '?' + query : ''),
      index: i,
      current: i === current,
    })
  }

  return {
    current: pages[current],
    first: pages[0],
    firstItemCount: totalCount ? offset + 1 : 0,
    last: pages[last],
    lastItemCount: Math.min(offset + limit, totalCount),
    next: pages[current + 1],
    pages,
    prev: pages[current - 1],
    totalCount,
  }
}

const Pagination = (props) => {
  const { firstItemCount, lastItemCount, totalCount, pages, prev, next } = getPages(props)

  return (
    <nav className={cn(s.base, props.className)} ref={props.forwardRef}>
      <div className={s.results}>
        <span className={cn(s.first, s.number)}>{firstItemCount}</span> -{' '}
        <span className={cn(s.last, s.number)}>{lastItemCount || 0}</span> av{' '}
        <span className={cn(s.total, s.number)}>{totalCount || 0}</span>
      </div>
      <ul className={s.pages}>
        {totalCount > 0 && (
          <Fragment>
            <li className={cn(s.arrow, s.prev)}>
              <a href={prev?.path || 'javascript:;'} rel={prev && 'prev'} className={s.nav} title='Föregående sida'>
                <span>Previous</span>
              </a>
            </li>

            {pages.map((page, i) => (
              <li className={cn(s.page, page.current && s.current)}>
                <a href={page.path} className={s.nav} rel={page.rel} title={`Sida ${i + 1}`}>
                  <span>{i + 1}</span>
                </a>
              </li>
            ))}

            <li className={cn(s.arrow, s.next)}>
              <a href={next?.path || 'javascript:;'} rel={next && 'next'} className={s.nav} title='Nästa sida'>
                <span>Next</span>
              </a>
            </li>
          </Fragment>
        )}
      </ul>
    </nav>
  )
}

export default Pagination
