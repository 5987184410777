import { h } from 'preact'
import s from './opening_hours.module.scss'
import cn from 'classnames'

const dayNames = {
  mo: 'Måndag',
  tu: 'Tisdag',
  we: 'Onsdag',
  th: 'Torsdag',
  fr: 'Fredag',
  sa: 'Lördag',
  su: 'Söndag',
}

const OpeningHours = ({ openingHours: { today, tomorrow, regular, special, days } }) => (
  <div classname={s.base}>
    <div className={cn(s.today, s.day)}>Idag {today ? `${today.opens} - ${today.closes}` : 'Stängt'}</div>
    <div className={cn(s.tomorrow, s.day)}>
      {' '}
      Imorgon {tomorrow ? `${tomorrow.opens} - ${tomorrow.closes}` : 'Stängt'}
    </div>
    <div className={s.regular}>
      {Object.entries(regular.dayOfWeek).map(([day, hours]) => [
        <div className={s.day}>{dayNames[day]}</div>,
        <div className={s.time}>{hours ? `${hours.opens} - ${hours.closes}` : 'Stängt'}</div>,
      ])}
    </div>
    <div>
      {special && (
        <div>
          <div>
            {name} {special.validFrom} - {special.validThrough}
          </div>
          {Object.entries(special.dayOfWeek).map(([day, hours]) => (
            <div>
              {dayNames[day]} {hours ? `${hours.opens} - ${hours.closes}` : 'Stängt'}
            </div>
          ))}
        </div>
      )}
      {days && (
        <div>
          {days.map(({ date, name, hours }) => (
            <div>
              {name || date.slice(0, 10)} {hours ? `${hours.opens} - ${hours.closes}` : 'Stängt'}
            </div>
          ))}
        </div>
      )}
    </div>
  </div>
)

export default OpeningHours
