/**
 * interface contentStore {
 *   current: Content
 *   items: [ Content ]
 * }
 */

export const updateContents = (routeId) => (getState, _dispatch, rek) => {
  const { contents } = getState()

  if (!contents || routeId !== contents.routeId) {
    const existing = contents && contents.items[routeId]

    if (existing) {
      return {
        contents: {
          routeId,
          current: existing,
          items: contents.items,
        },
      }
    }

    return rek(`/api/contents/by-route?routeId=${routeId}`).then((contents) => {
      return {
        contents: {
          routeId,
          current: contents,
          items: {
            ...(getState().contents?.items || {}),
            [routeId]: contents,
          },
        },
      }
    })
  }
}
