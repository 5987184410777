import { h } from 'preact'

import Logo from './logo.jsx'
import LogoShield from './logo_shield.jsx'
import Navigation from './navigation.jsx'
import RecoMini from './reco_mini.jsx'
import LanuageSelector from './language_selector.jsx'

import s from './header.module.scss'

export default ({ locale, locales, openingHours, organization, location, routes }) => (
  <header className={s.base}>
    <h1 className={s.logoContainer}>
      <span>{organization && organization.name}</span>
      <Logo className={s.logo} />
      <LogoShield className={s.logoShield} />
    </h1>

    <div className={s.contactDetails}>
      <div className={s.email}>{organization.email}</div>
      <div className={s.location}>
        {organization.locality}, {organization.region}
      </div>
      <div className={s.openingHours}>
        <span class={s.label}>Idag</span>{' '}
        {openingHours && (
          <span className={s.value}>
            {openingHours.today ? `${openingHours.today.opens} - ${openingHours.today.closes}` : 'Stängt'}
          </span>
        )}
      </div>
      <div className={s.telephone}>{organization.telephone}</div>
    </div>

    <RecoMini className={s.reco} />

    <div className={s.social}>
      <a className={s.facebook} href='https://facebook.com'>
        <span>LinkedIn</span>
      </a>
      <a className={s.instagram} href='https://instagram.com'>
        <span>Facebook</span>
      </a>
      <a className={s.twitter} href='https://twitter.com'>
        <span>Twitter</span>
      </a>
      <a className={s.linkedin} href='https://linkedin.com'>
        <span>LinkedIn</span>
      </a>
    </div>

    <LanuageSelector className={s.language} locales={locales} current={locale} />

    <Navigation className={s.navigation} locale={locale} location={location} routes={routes} />
  </header>
)
