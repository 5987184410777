import { h } from 'preact'
import cn from 'classnames'
import s from './employee.module.scss'

export default ({ className, email, givenName, familyName, telephone, jobTitle, image }) => (
  <article className={cn(s.base, className)}>
    <figure className={s.imageContainer}>
      <img
        width='100'
        height='100'
        className={s.image}
        src={`/uploads/${image}`}
        alt=''
        title={`${givenName} ${familyName}`}
      />
    </figure>

    <div className={s.info}>
      <h1 className={s.name}>
        {givenName} {familyName}
      </h1>
      <p className={s.jobTitle}>{jobTitle}</p>

      {telephone && (
        <div className={cn(s.property, s.telephone)}>
          <div>Mobil:</div>
          <a href={`tel:${telephone.replace(/\D/g, '')}`}>{telephone}</a>
        </div>
      )}

      {email && (
        <div className={cn(s.property, s.email)}>
          <div>Epost:</div>
          <div>
            <a href={`mailto:${email}`}>{email}</a>
          </div>
        </div>
      )}
    </div>
  </article>
)
