import { h } from 'preact'
import { useCallback, useEffect, useRef, useState } from 'preact/hooks'
import cn from 'classnames'
import escapeRegex from '../utils/escape_regex.js'

/**
 * @param {{
 *   base?: string
 *   touched?: string
 *   element?: string
 *   label?: string
 * }} styles
 * @returns {import('preact').FunctionComponent<{
 *    autoFocus?: boolean
 *    autoComplete?: string
 *    className?: string
 *    defaultValue?: string
 *    disabled?: boolean
 *    name: string
 *    noMargin?: boolean
 *    label: string
 *    pattern?: string
 *    placeholder?: string
 *    required?: boolean
 *    type?: string
 * }>}
 */
export default function inputFactory(styles) {
  const Input = ({
    autoComplete,
    autoFocus,
    className,
    defaultValue,
    disabled,
    icon,
    label,
    name,
    noMargin,
    pattern,
    placeholder,
    required,
    sameAs,
    type = 'text',
  }) => {
    const [touched, setTouched] = useState(false)
    const inputRef = useRef()

    const onBlur = useCallback(() => setTouched(true), [])

    useEffect(() => {
      function onInput(e) {
        inputRef.current.pattern = escapeRegex(e.target.value)
      }

      const form = inputRef.current.form
      const resetTouched = setTouched.bind(null, false)

      form.addEventListener('reset', resetTouched)

      let sameAsInput

      if (sameAs) {
        sameAsInput = form[sameAs]
        sameAsInput.addEventListener('input', onInput)
      }

      return () => {
        form.removeEventListener('reset', resetTouched)
        sameAsInput && sameAsInput.removeEventListener('input', onInput)
      }
    }, [])

    useEffect(() => {
      if (autoFocus) inputRef.current.focus()
    }, [autoFocus])

    const id = styles.element + '_' + name

    return (
      <div className={cn(styles.base, noMargin && styles.noMargin, touched && styles.touched, className)}>
        {icon && <div className={cn(styles.icon)} style={{ maskImage: icon }} />}

        <input
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          className={styles.element}
          defaultValue={
            defaultValue && type === 'datetime-local' ? new Date(defaultValue).toLocaleString('sv-SE') : defaultValue
          }
          disabled={disabled}
          id={id}
          name={name}
          onBlur={onBlur}
          pattern={pattern}
          placeholder={placeholder ?? label}
          ref={inputRef}
          required={required}
          type={type}
        />

        {/**
         * <label> comes after <input> to be able to use next sibling CSS selector
         * (see public input.module.scss). Use `flexbox` and `order` if <label> needs to be
         * displayed before <input> (see admin input.module.scss)
         */}
        {label && (
          <label className={styles.label} htmlFor={id}>
            {label}
          </label>
        )}
      </div>
    )
  }

  return Input
}
