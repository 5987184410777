import { h } from 'preact'
import { useDispatch, useSelector } from '@bmp/store/preact'
import cn from 'classnames'

import { useLoading } from '../contexts/loading.js'
import { fetchLatestCars, unloadLatestCars } from '../store/latest_cars_actions.js'
import useIsomorphicEffect from '../util/use_isomorphic_effect.js'

import Car from './car.jsx'
import Slider from './slider.jsx'
import s from './latest_cars.module.scss'

const LatestCars = ({ className }) => {
  const loading = useLoading()
  const dispatch = useDispatch()
  const { latestCars, locale } = useSelector(['latestCars', 'locale'])

  useIsomorphicEffect(() => {
    if (!latestCars) {
      const done = loading()

      dispatch(fetchLatestCars()).then(done)
    }

    return () => dispatch(unloadLatestCars())
  }, [])

  return (
    <Slider className={cn(s.slider, className)}>
      {latestCars &&
        latestCars.map((car) => <Car {...car} basePath={locale.default ? '/bilar' : '/cars'} className={s.slide} />)}
    </Slider>
  )
}

export default LatestCars
