export const updateTranslations = (routeId) => (getState, _dispatch, rek) => {
  const { translations } = getState()

  if (!translations || routeId !== translations.routeId) {
    const existing = translations && translations.items[routeId]

    if (existing !== undefined) {
      return {
        translations: {
          routeId,
          global: translations.global,
          route: existing,
          items: translations.items,
        },
      }
    }

    return rek(`/api/translations/by-route?routeId=${routeId}`).then((translations) => ({
      translations: {
        routeId,
        global: translations.global,
        route: translations.route || null,
        items: Object.assign(
          {
            [routeId]: translations.route || null,
          },
          getState().translations?.items,
        ),
      },
    }))
  }
}
