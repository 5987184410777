import { h } from 'preact'
import Logo from './logo.jsx'

import { useTranslate } from '../hooks/translate.js'
import FooterNavigation from './footer_navigation.jsx'
import s from './footer.module.scss'

const Footer = ({ routes, openingHours, organization, location }) => {
  const translate = useTranslate()

  return (
    <div className={s.base}>
      {organization && (
        <div className={s.top}>
          <div className={s.about}>
            <Logo className={s.logo}>Carson</Logo>
            <p>Vi är med dig igenom hela ditt bil ägande.</p>
            <p>Service, reparation, finansiering, bilvård, försäljning, köp samt rådgivning.</p>
            <p>Det skall vara enkelt och tryggt att köpa en bil.</p>
          </div>

          <div className={s.links}>
            <h2>{translate('Länkar')}</h2>

            <FooterNavigation routes={routes} location={location} />
          </div>

          <div className={s.contact}>
            <h2>{translate('Kontakt')}</h2>
            <p className={s.telephone} property='telephone'>
              {organization.telephone}
            </p>
            <p className={s.email} property='email'>
              <a href={`mailto:${organization.email}`}>{organization.email}</a>
            </p>
            <p className={s.address}>
              <span property='address' typeof='PostalAddress'>
                <span property='streetAddress'>{organization.streetAddress}</span>
                <br />
                <span property='postalCode'>{organization.postalCode}</span>{' '}
                <span property='addressLocality'>{organization.locality}</span>
                <br />
                <span property='addressCountry'>{organization.country}</span>
              </span>
            </p>
            {openingHours && (
              <p className={s.openingHours}>
                <div className={s.day}>
                  <div className={s.label}>Idag</div>
                  <div className={s.value}>
                    {openingHours.today ? `${openingHours.today.opens} - ${openingHours.today.closes}` : 'Stängt'}
                  </div>
                </div>
                <div className={s.day}>
                  <div className={s.label}>Imorgon</div>
                  <div className={s.value}>
                    {openingHours.tomorrow
                      ? `${openingHours.tomorrow.opens} - ${openingHours.tomorrow.closes}`
                      : 'Stängt'}
                  </div>
                </div>
              </p>
            )}
          </div>

          <div className={s.social}>
            <div className={s.newsletter}>
              <h2>{translate('Nyhetsbrev')}</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

              <input type='text' />
            </div>

            <div className={s.follow}>
              <h2>{translate('Följ oss')}</h2>

              <div className={s.icons}>
                <a className={s.facebook} href='https://facebook.com'>
                  <span>LinkedIn</span>
                </a>
                <a className={s.instagram} href='https://instagram.com'>
                  <span>Facebook</span>
                </a>
                <a className={s.twitter} href='https://twitter.com'>
                  <span>Twitter</span>
                </a>
                <a className={s.linkedin} href='https://linkedin.com'>
                  <span>LinkedIn</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={s.bottom}>
        <h2>{translate('Våra samarbetspartners')}</h2>
        <div className={s.logos}>
          <a className={s.bytbil} href='https://www.bytbil.com/handlare/carson-sverige-ab-6147' target='_blank'>
            <img src='/img/partner-bytbil.svg' alt='Bytbil' />
          </a>
          <a className={s.dnb} href='https://www.dnb.se/se/sv' target='_blank'>
            <img src='/img/partner-dnb.svg' alt='DnB' />
          </a>
          <a className={s.solid} href='https://www.solidab.se/' target='_blank'>
            <img src='/img/partner-solid.svg' alt='Solid Försäkringar' />
          </a>
          <a className={s.blocket} href='https://www.blocket.se/butik/carson' target='_blank'>
            <img src='/img/partner-blocket.svg' alt='Blocket' />
          </a>
        </div>
      </div>
    </div>
  )
}
export default Footer
