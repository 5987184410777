const re = /((https?:)\/\/)?([^/]+)?(\/[^?]*)(.*)/

export const changeLocation = (url, route) => (getState) => {
  const { location } = getState()

  const [, , , , pathname = '', search = ''] = url.match(re) || []

  return {
    location: {
      ...location,
      pathname,
      search,
      route,
    },
  }
}
