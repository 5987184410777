import './styles/main.scss'

import rek from 'rek'
import { h, hydrate } from 'preact'
import { Provider } from '@bmp/store/preact'
import Layout from './components/layout.jsx'
import createStore from './store/index.js'

const preloadedState = typeof __STATE__ !== 'undefined' ? __STATE__ : undefined

// eslint-disable-next-line no-undef
const store = createStore(preloadedState, { extraArg: rek }, process.env.NODE_ENV === 'development')

hydrate(h(Provider, { store }, h(Layout)), document.body)
