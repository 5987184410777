import { h } from 'preact'
import cn from 'classnames'

import { useTranslate } from '../hooks/translate.js'
import Button from './button.jsx'
import Reco from './reco.jsx'
import LatestCars from './latest_cars.jsx'
import FrontSearchForm from './front_search_form.jsx'

import s from './start_page.module.scss'
import sLayout from './layout.module.scss'

const StartPage = () => {
  const translate = useTranslate()

  return (
    <section className={cn(sLayout.page, s.base)}>
      <section className={s.hero}>
        <div className={s.sellYourCar}>
          <h1>Vi köper din bil</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat.
          </p>
          <Button color='white' href='/salj-din-bil'>
            Sälj bil
          </Button>
        </div>
      </section>

      <div className={s.search}>
        <FrontSearchForm />
      </div>

      <section className={s.latestCars}>
        <h1>{translate('Senast inkomna bilar')}</h1>
        <LatestCars className={s.latestCarsComponent} />
        <a href='/bilar'>Visa alla</a>
      </section>

      <div className={s.recoWrapper}>
        <section className={s.reco}>
          <h1>Våra kunder</h1>
          <Reco className={s.section} />
        </section>
      </div>

      <div className={s.workshopWrapper}>
        <div className={s.workshop}>
          <h1>Vi har vår egen verkstad</h1>

          <p>
            Köper du bil hos oss på Carson så erbjuder vi att även i framtiden serva och ta hand om din bil. Det gör vi
            i vår egen verkstad. Vi erbjuder även fri lånebil och låga priser, samt att vi har mycket kompententa
            servicetekniker som bryr sig om din bil!
          </p>
        </div>
      </div>

      <section className={s.services}>
        <h1>Fler av våra tjänster</h1>
        <p>Vi tillhandahåller flertal tjänster runt om själva bilhandlandet.</p>

        <section className={s.förmedling}>
          <h1>Förmedling & köp</h1>
          <p>Trygg bilförmedling och snabb betalning vid försäljning eller köp hos Carson</p>
        </section>

        <section className={s.försäljning}>
          <h1>Försäljning & inbyte</h1>
          <p>Carson erbjuder ett brett utbud av testade bilar och tar din bil i inbyte med snabb värdering.</p>
        </section>

        <section className={cn(s.finansiering)}>
          <h1>Finansiering</h1>
          <p>Vi hjälper dig hitta en passande finansieringslösning, inklusive företagsleasing från 0 kr kontant.</p>
        </section>

        <section className={cn(s.försäkring)}>
          <h1>Försäkring</h1>
          <p>Vi hjälper dig att försäkra din bil med de bästa försäkringslösningarna från flera samarbetspartners.</p>
        </section>
      </section>
    </section>
  )
}

export default StartPage
