import { h } from 'preact'
import { useCallback, useState } from 'preact/hooks'
import cn from 'classnames'

import Slider from './slider.jsx'
import Spots from './spots.jsx'
import s from './image_gallery.module.scss'

const ImageGallery = ({ className, images, imageTitle }) => {
  const [currentImage, setCurrentImage] = useState({ selected: null, current: 0 })

  const onTopSlide = useCallback(({ currentView }) => {
    setCurrentImage((currentState) => ({ current: currentView, selected: currentState.selected }))
  }, [])

  const onTopSlideEnd = useCallback(({ currentView }) => {
    setCurrentImage({ current: currentView, selected: null })
  }, [])

  return (
    <section className={cn(s.base, className)}>
      <div className={s.topSliderContainer}>
        <Slider
          className={s.topSlider}
          currentSlide={currentImage.selected}
          onSlide={onTopSlide}
          onSlideEnd={onTopSlideEnd}
        >
          {images &&
            images.map((image, index) => (
              <figure key={image} className={cn(s.topSlide, index === currentImage && s.current)}>
                <img src={image} title={imageTitle} />
              </figure>
            ))}
        </Slider>

        <Spots light current={currentImage.current} count={images.length} className={s.spots} />
      </div>

      <Slider className={s.bottomSlider} currentSlide={currentImage.selected ?? currentImage.current}>
        {images &&
          images.map((image, index) => (
            <figure
              key={image}
              className={cn(s.bottomSlide, index === (currentImage.selected ?? currentImage.current) && s.current)}
              onClick={() => setCurrentImage((currentState) => ({ current: currentState.current, selected: index }))}
            >
              <img alt='' src={image} title='' />
            </figure>
          ))}
      </Slider>
    </section>
  )
}

export default ImageGallery
