import { h } from 'preact'
import { useCallback, useState } from 'preact/hooks'
import calculateMonthly from '../util/calculate_monthly.js'
import { formatPrice } from '../util/format_number.js'

import Range from './new_form/range.jsx'

import s from './finance.module.scss'

const Finance = ({ price }) => {
  const [values, setValues] = useState({
    cash: price * 0.2,
    rest: price * 0.5,
    time: 36,
  })

  const setValue = useCallback((value, name) => {
    setValues((current) => ({ ...current, [name]: value }))
  }, [])

  return (
    <section className={s.base}>
      <div className={s.field}>
        <label className={s.rangeLabel} for='cash'>
          Kontantinsats
        </label>
        <output className={s.rangeOutput} for='cash'>
          {formatPrice(values.cash)}
        </output>
        <Range
          className={s.range}
          color='white'
          name='cash'
          id='cash'
          min={price * 0.2}
          max={price * 0.5}
          onInput={setValue}
          defaultValue={price * 0.2}
        />
      </div>

      <div className={s.field}>
        <label className={s.rangeLabel} for='time'>
          Avbetalningstid
        </label>
        <output className={s.rangeOutput} for='time'>
          {values.time} mån
        </output>
        <Range
          className={s.range}
          color='white'
          name='time'
          id='time'
          min={12}
          max={72}
          onInput={setValue}
          defaultValue={36}
        />
      </div>

      <div className={s.field}>
        <label className={s.rangeLabel} for='rest'>
          Restvärde
        </label>
        <output className={s.rangeOutput} for='rest'>
          {formatPrice(values.rest)}
        </output>
        <Range
          className={s.range}
          color='white'
          name='rest'
          id='rest'
          min={0}
          max={price * 0.5}
          onInput={setValue}
          defaultValue={price * 0.5}
        />
      </div>

      <div className={s.result}>
        <label className={s.resultLabel} for='result'>
          Prisförslag{' '}
        </label>
        <output className={s.resultOutput} id='result' for='cash,time,rest'>
          {formatPrice(calculateMonthly(price, values))} / mån
        </output>
      </div>
      <p className={s.description}>Aviavgift och uppläggningsavgift tillkommer.</p>
    </section>
  )
}

export default Finance
