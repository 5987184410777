export default function (form, hook) {
  const result = {}

  for (const element of form.elements) {
    const { checked, name, type, value } = element

    if (
      (!hook || !hook(result, name, value, element)) &&
      name &&
      value &&
      (checked || (type !== 'checkbox' && type !== 'radio'))
    ) {
      if (result[name]) {
        const arr = Array.isArray(result[name]) ? result[name] : [result[name]]
        arr.push(value)
        result[name] = arr
      } else {
        result[name] = value
      }
    }
  }

  return result
}
