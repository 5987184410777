import { h, toChildArray } from 'preact'
import Router from 'preact-router'
import LoadingContext from '../contexts/loading.js'
import { get } from 'lowline'

const LOADING_CLASSNAME = 'loading'

function remember() {
  window.history.replaceState(
    {
      ...window.history.state,
      scrollTop: window.pageYOffset,
    },
    '',
    null,
  )
}

let eventListenersInitialized = false

function initEventListeners() {
  if (eventListenersInitialized) {
    return
  }

  let timeout

  if (typeof addEventListener === 'function') {
    addEventListener('scroll', () => {
      if (timeout) {
        clearTimeout(timeout)
      }

      timeout = setTimeout(remember, 200)
    })
  }

  eventListenersInitialized = true
}

const pathname = (url) => url && url.split('?')[0]

export default class BMRouter extends Router {
  updating = false

  currentChild = null
  currentUrl = null
  previousChild = null

  previousUrl = null

  waitingFor = new Set()

  constructor(props) {
    super(props)

    if (typeof history !== 'undefined') {
      history.scrollRestoration = 'manual'
    }

    initEventListeners()
  }

  /** Re-render children with a new URL to match against. */
  routeTo(url) {
    let didRoute = false

    if (url !== this.currentUrl) {
      const canRoute = this.canRoute(url)

      if (canRoute) {
        document.body.classList.add(LOADING_CLASSNAME)

        didRoute = true
      }

      this.setState({ url, loading: canRoute })
    }

    // trigger a manual re-route if we're not in the middle of an update:
    if (!this.updating) this.forceUpdate()

    return didRoute
  }

  componentDidUpdate(_prevProps, prevState) {
    super.componentDidUpdate()

    if (this.state.loading && this.waitingFor.size === 0) {
      // eslint-disable-next-line
      this.setState({
        loading: false,
      })
    }

    if (!this.state.loading && document.body.classList.contains(LOADING_CLASSNAME)) {
      document.body.classList.remove(LOADING_CLASSNAME)
    }

    if (!this.state.loading && prevState.loading) {
      const offset = get(window.history, 'state.scrollTop')

      if (offset != null || pathname(this.currentUrl) !== pathname(this.previousUrl)) {
        window.scrollTo(0, offset || 0)
      }
    }
  }

  registerWait = () => {
    const fnc = () => {
      this.waitingFor.delete(fnc)

      // TODO settimeout to ensure we dont "unload" before other loaders have had time to register?
      if (this.state.loading && this.waitingFor.size === 0) {
        this.setState({
          loading: false,
        })
      }
    }

    this.waitingFor.add(fnc)

    return fnc
  }

  render({ children, onChange }, { loading, url }) {
    const active = this.g(toChildArray(children), url, true)

    const current = active[0] || null

    const currentUrl = this.currentUrl

    if (url !== currentUrl) {
      this.currentUrl = url
      this.previousUrl = currentUrl
      this.previousChild = this.currentChild
      this.currentChild = current

      if (typeof onChange === 'function') {
        onChange({
          router: this,
          url,
          previous: currentUrl,
          active,
          current,
        })
      }
    }

    const currentPath = pathname(this.currentUrl)
    const previousPath = pathname(this.previousUrl)

    if (currentPath === previousPath || !loading || !this.previousChild) {
      return [
        <LoadingContext.Provider key={currentPath} value={this.registerWait}>
          {current}
        </LoadingContext.Provider>,
      ]
    }

    return [
      <LoadingContext.Provider key={currentPath} value={this.registerWait}>
        {current}
      </LoadingContext.Provider>,
      <LoadingContext.Provider key={previousPath} value={this.registerWait}>
        {this.previousChild}
      </LoadingContext.Provider>,
    ]
  }
}
